.Download {
  display: flex;
  justify-content: center;

  &__button {
    position: relative;

    &:hover {
      .qr {
        @media (min-width: 426px) {
          display: block;
        }
      }
    }

    a {
      position: relative;
      display: block;

      img {
        display: block;
      }
    }

    .qr {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      z-index: 100;

      img {
        width: 100%;
        display: block;
      }
    }
  }

  &__store_image {
    display: block;
    height: 100%;
    aspect-ratio: calc(564 / 168);
    background-size: cover;

    &.apple {
      background-image: url(../../../public/img/apple-store.png);
    }

    &.android {
      background-image: url(../../../public/img/google-play.png);
    }
  }
}
