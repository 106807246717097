/***** Footer Styles *****/

$breakpoint: 850px;

.footer {
  background-color: #000;
  color: #fff;
  font-size: 16px;
  padding: 32px 0;
  border-top: 1px solid #969696;

  @media (max-width: $breakpoint) {
    padding: 40px;
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: $breakpoint) {
      display: block;
      text-align: center;
    }
  }

  .bottom-navigation {
    @media (max-width: $breakpoint) {
      margin-bottom: 40px;
    }

    ul {
      li {
        display: inline;
        margin-right: 32px;

        @media (max-width: $breakpoint) {
          display: block;
          text-align: center;
          margin: 0 0 16px;
        }
      }
    }
  }

  a,
  a:hover,
  a:active,
  a:visited {
    color: #fff;
    text-decoration: none;
  }
}
