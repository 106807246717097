.Button {
  border-radius: 16px;
  font-size: 16px;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  height: 40px;
  padding: 0 16px;
  border: none;
}
