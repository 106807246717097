* {
  box-sizing: border-box;
}

body {
  background-color: #fff;
  font-size: 20px;
  line-height: 30px;
}

b {
  font-weight: 400;
  text-decoration: underline;
}

i {
  font-style: italic;
}

body,
h1,
h2,
h3,
.button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #000;
}

input {
  display: block;
  width: 100%;
  padding: 0 8px;
  line-height: 36px;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.14);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.button {
  background-color: #000;
  color: #fff;
  border: 0;
  border-radius: 3px;
  line-height: 36px;
  padding: 0 10px;
  cursor: pointer;
}

.content-wrapper {
  max-width: 1106px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;

  @media (max-width: 650px) {
    padding: 0 24px;
  }

  @media (max-width: 400px) {
    padding: 0 14px;
  }

  &.legal {
    max-width: 900px;
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

h1 {
  font-size: 30px;
  line-height: 38px;

  @media (max-width: 760px) {
    font-size: 25px;
    line-height: 32px;
  }
}

h2 {
  font-size: 28px;
  line-height: 34px;
  // font-weight: 500;

  @media (max-width: 760px) {
    font-size: 26px;
    line-height: 36px;
  }
}

h3 {
  font-size: 24px;
  line-height: 30px;
  // font-weight: 500;

  @media (max-width: 760px) {
    font-size: 22px;
    line-height: 26px;
  }
}

.img {
  max-width: 100%;
  max-height: 100%;
}

a,
a:active,
a:visited {
  color: #000;
}

a:hover {
  color: #969696;
}

/***** Privacy policy & Terms of Use Styles *****/
.legal {
  h1 {
    margin-bottom: 32px;
  }

  h2,
  h3 {
    margin: 24px 0 16px 0;
  }

  h4 {
    font-size: 20px;
    // font-weight: 500;
    margin: 16px 0 16px;
  }

  p {
    margin-bottom: 8px;

    strong {
      text-decoration: underline;
    }
  }

  a {
    word-break: break-all;
  }

  ul {
    padding-left: 56px;
    margin-bottom: 8px;

    li {
      list-style-type: circle;
    }
  }
}

.body-no-scroll {
  overflow: hidden;
}
