.Spot {
  padding-top: 32px;
  padding-bottom: 100px;

  @media (max-width: 425px) {
    padding-top: 16px;
  }

  article {
    margin-bottom: 32px;

    @media (max-width: 425px) {
      margin-bottom: 16px;
    }

    h1 {
      font-size: 24px;
      line-height: 26px;
      margin-bottom: 16px;
    }

    h2 {
      font-size: 20px;
      line-height: 22px;
      margin-top: 24px;
      margin-bottom: 8px;
    }
  }

  &__wrapper {
    display: flex;

    @media (max-width: 700px) {
      flex-direction: column;
    }
  }

  &__photo {
    position: relative;
    flex: 1;

    img {
      display: block;
    }

    figcaption {
      display: none;
    }
  }

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: #0005;
    color: #fff;
    font-size: 20px;
    transform: translateY(-50%);
    cursor: pointer;

    &--left {
      left: 16px;
    }

    &--right {
      right: 16px;
    }
  }

  &__by {
    font-size: 12px;
    line-height: 14px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: rgba(#fff, 0.5);
    padding: 4px;
    border-radius: 4px 0 0 0;

    &:hover {
      color: #000;
    }
  }

  &__show-map {
    margin-top: 24px;

    .Button {
      width: 100%;
    }
  }

  &__map {
    flex: 1;

    @media (max-width: 700px) {
      flex: auto;
      margin: 24px 0 0;
      height: 300px;
    }
  }

  &__description {
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__we {
    width: 80%;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    margin: 0 auto 32px;

    @media (max-width: 700px) {
      width: 100%;
      font-size: 16px;
      line-height: 22px;
      margin: 0 auto 16px;
    }
  }

  &__download {
    max-width: 400px;
    text-align: center;
    margin: 16px auto 32px;

    .Download {
      margin-top: 16px;
      justify-content: space-between;

      > div {
        width: 48%;
      }
    }
  }
}
