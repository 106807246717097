.Slider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .photo-wrapper {
    width: 25vw;
    height: 17vw;

    @media (max-width: 425px) {
      width: 50vw;
      height: 32vw;
    }

    .photo {
      width: 100%;
      height: 100%;
      z-index: 1;
      cursor: pointer;
      object-fit: cover;
    }
  }
}
