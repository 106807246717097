.Loading {
  margin: 0 auto;
  padding: 32px 0;
  text-align: center;
  font-size: 24px;

  &__emoji {
    margin-top: 24px;
    font-size: 32px;
  }
}
