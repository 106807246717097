.Home {
  .hero {
    display: flex;

    @media (max-width: 862px) {
      flex-direction: column;
    }

    .main-description {
      width: 50%;
      padding: 40px;

      @media (max-width: 862px) {
        padding: 24px 0;
        width: 100%;
      }
    }

    .download-container {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 862px) {
        width: 100%;
      }

      .Download {
        &__button {
          height: 50px;

          @media (max-width: 425px) {
            height: 40px;
          }

          &.google-play {
            margin-left: 32px;

            @media (max-width: 425px) {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }

  .sliders {
    width: 100%;
    // padding: 20px 0 80px;

    @media (max-width: 862px) {
      // padding: 20px 0 40px;
      margin-top: 40px;
    }

    h2 {
      text-align: center;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    .Slider {
      // margin-bottom: 80px;

      @media (max-width: 862px) {
        // margin-bottom: 40px;
      }
    }

    .cities {
      display: flex;
      justify-content: center;
      text-align: center;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }

      a + a {
        margin-left: 24px;

        @media (max-width: 768px) {
          margin-left: 0;
        }
      }
    }

    button {
      display: block;
      background-color: #000;
      border: 0;
      color: #fff;
      padding: 0 30px;
      min-height: 40px;
      line-height: 40px;
      margin: 30px auto;
      cursor: pointer;
      font-size: 20px;
      border-radius: 20px;
      outline: none;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);

      &:hover {
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
          0 3px 1px -1px rgba(0, 0, 0, 0.2);
      }

      @media (max-width: 500px) {
        font-size: 16px;
      }

      @media (max-width: 425px) {
        font-size: 12px;
        min-height: 30px;
        line-height: 30px;
        border-radius: 15px;
      }
    }
  }

  .features {
    background-color: #f2f2f2;
    padding: 96px 0;

    @media (max-width: 760px) {
      padding: 56px 0;
    }

    @media (max-width: 538px) {
      padding-bottom: 92px;
    }

    h2 {
      text-align: center;
      margin-bottom: 56px;

      @media (max-width: 862px) {
        margin-bottom: 48px;
      }
    }

    .features-wrapper {
      display: flex;
      justify-content: space-between;

      @media (max-width: 862px) {
        display: block;
      }

      .features-item {
        flex: 1;
        padding: 0 24px;

        @media (max-width: 862px) {
          max-width: 450px;
          margin: 48px auto 0;
          padding: 0;
        }

        .feature-item__icon {
          width: 50px;
          height: 50px;
          background-size: cover;
          margin: 0 auto 24px;
        }

        &.get-details {
          .feature-item__icon {
            background-image: url(../../../public/img/add_a_photo.svg);
          }
        }

        &.create-collections {
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;

          @media (max-width: 862px) {
            border-left: none;
            border-right: none;
          }

          .feature-item__icon {
            background-image: url(../../../public/img/quality.svg);
          }
        }

        &.share-your-story {
          .feature-item__icon {
            background-image: url(../../../public/img/free.svg);
          }
        }

        h3 {
          margin-bottom: 16px;
        }

        h3,
        .text {
          text-align: center;
        }
      }
    }
  }

  /***** Social Feature Styles *****/
  .social-feature {
    height: 508px;
    display: flex;

    @media (max-width: 760px) {
      height: 360px;
    }

    @media (max-width: 538px) {
      display: block;
      height: auto;
    }

    .images {
      position: relative;
      width: 50%;

      @media (max-width: 760px) {
        width: 40%;
      }

      @media (max-width: 538px) {
        width: 100%;
      }

      img {
        display: block;
      }

      .profile {
        background-image: url(../../../public/img/screenshot-profile.jpg);
        background-size: cover;
        width: 322px;
        aspect-ratio: calc(322 / 554);
        box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);
        position: absolute;
        right: 0;
        top: 48px;

        @media (max-width: 1200px) {
          width: 300px;
        }

        @media (max-width: 760px) {
          width: 260px;
          top: 32px;
        }

        @media (max-width: 650px) {
          width: 210px;
          top: 32px;
        }

        @media (max-width: 538px) {
          width: 203px;
          top: -36px;
          left: 50%;
          transform: translateX(-10%);
        }

        @media (max-width: 400px) {
          width: 174px;
          transform: translateX(-8%);
        }
      }

      .home {
        background-image: url(../../../public/img/screenshot-home.jpg);
        background-size: cover;
        width: 222px;
        aspect-ratio: calc(222 / 382);
        box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 125px;
        right: 294px;

        @media (max-width: 1200px) {
          width: 200px;
          top: 157px;
          right: 180px;
        }

        @media (max-width: 760px) {
          top: 110px;
        }

        @media (max-width: 538px) {
          width: 172px;
          top: 0;
          left: 50%;
          transform: translateX(-105%);
        }

        @media (max-width: 400px) {
          width: 146px;
          transform: translateX(-110%);
        }
      }
    }

    .content {
      width: 50%;
      display: flex;
      align-items: center;

      @media (max-width: 760px) {
        width: 60%;
      }

      @media (max-width: 650px) {
        width: 65%;
      }

      @media (max-width: 538px) {
        width: 100%;
        padding-top: 372px;
        padding-bottom: 56px;
      }

      @media (max-width: 400px) {
        padding-top: 316px;
      }

      .social-feature-description {
        max-width: 553px;
        padding-left: 130px;
        padding-right: 32px;

        @media (max-width: 1200px) {
          padding-left: 60px;
        }

        @media (max-width: 650px) {
          padding-left: 50px;
        }

        @media (max-width: 538px) {
          padding: 0 24px;
        }

        @media (max-width: 400px) {
          padding: 0 14px;
        }

        h2 {
          text-align: left;
          margin-bottom: 24px;
        }

        .text {
          text-align: left;
        }
      }
    }
  }
}
