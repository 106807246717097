/***** Header Styles *****/

.header {
  height: 84px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 760px) {
    height: 72px;
  }

  @media (max-width: 425px) {
    height: 50px;
  }

  .content-wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    background-image: url(../../../public/img/nofilter-logo.png);
    background-size: cover;
    // Original size: 544 x 150
    height: 50px;
    width: calc(544px / 3);

    @media (max-width: 760px) {
      height: 40px;
      width: calc(544px / 3.75);
    }

    @media (max-width: 425px) {
      height: 30px;
      width: calc(544px / 5);
    }
  }

  a {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #000;
    text-decoration: none;
    display: flex;

    &.contact {
      @media (max-width: 650px) {
        display: none;
      }
    }
  }
}
