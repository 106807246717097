.JoinToday {
  position: relative;
  background-color: #000;
  padding: 96px 0;

  @media (max-width: 760px) {
    padding: 56px 0;
  }

  h2 {
    color: #fff;
    text-align: center;
    margin-bottom: 40px;

    @media (max-width: 760px) {
      margin-bottom: 24px;
    }
  }

  &__download-wrapper {
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
  }

  .Download {
    &__button {
      height: 50px;

      @media (max-width: 760px) {
        height: 40px;
      }

      &.google-play {
        margin-left: 32px;

        @media (max-width: 760px) {
          margin-left: 16px;
        }
      }
    }
  }
}
